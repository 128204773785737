<template>
    <v-dialog v-model="dialog" persistent max-width="650px" @click:outside="cerrarDialogo()">
        <v-card>
            <v-card-title>
                Respuesta a la Incidencia  {{ datos_param.identificador }}
            </v-card-title>
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Datos de Cliente</span>
            </v-toolbar>
            <v-card-text>
                <v-row class="pt-4">
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field label="Razon social" hide-details="auto" v-model="datos_param.razon_social" small
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-text-field label="RUC *" hide-details="auto" v-model="datos_param.ruc" small
                            readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text> 
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Detalles del Incidente</span>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row class="mt-2">
                        <v-col cols="12" sm="7" md="7">
                            <v-text-field label="Usuario" hide-details="auto" v-model="datos_param.usuario.name" small
                            readonly></v-text-field> 
                        </v-col>
                        <v-col cols="12" sm="5" md="5">
                            <v-text-field label="Fecha de registro" hide-details="auto" v-model="datos_param.fecha" small
                            readonly></v-text-field> 
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea class="letra-12" autocomplete="Descripcion" label="Descripción"
                                v-model="datos_param.descripcion"  readonly rows="2" />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <p><b>Imagen incidencia 1</b></p>
                            <div class="d-sm-flex justify-center">
                                <v-col cols="12" class="d-flex justify-center align-center px-0" >
                                    <img v-if="datos_param.image_one!=null" id='imagenFirmaPrevio' :src="datos_param.image_one ? image_one : image_one"
                                        alt="imagen_no_disponible" width='100' height='100'>
                                    <div v-else>No se ha cargado ninguna imagen</div>
                                </v-col> 
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <p><b>Imagen incidencia 2</b></p>
                                <v-col cols="12" class="d-flex justify-center align-center px-0" >
                                    <img v-if="datos_param.image_two!=null" id='imagenFirmaPrevio' :src="datos_param.image_two ? image_two : image_two"
                                        alt="imagen_no_disponible" width='100' height='100'>
                                    <div v-else>No se ha cargado ninguna imagen</div>
                                </v-col> 
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" v-if="datos_param.id_vehiculo!=null || datos_param.id_neumatico!=null">
                        
                        <table class="table table-hover table-sm letra-10" style="width: 100%">
                            <thead>
                                <tr>
                                    <th class="border  pa-1 pl-2 pr-2" style="width: 20%" rowspan="2">
                                        <strong>
                                            <font color="white">Placa</font>
                                        </strong>
                                    </th>
                                    <th class="border  pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                        <strong>
                                            <font color="white">Serie</font>
                                        </strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in datos_param.neumatico">
                                    <td class="border  pa-1 pl-1 pr-1">{{datos_param.vehiculo}}</td>
                                    <td class="border  pa-1 pl-1 pr-1">{{ item }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <v-col cols="12" sm="6" md="6">
                            <v-text-field  label="Vehiculo"  hide-details="auto" v-model="datos_param.vehiculo" small
                            readonly></v-text-field> 
                        </v-col>
                        <v-col cols="12" sm="6" md="5">
                            <v-text-field label="Neumatico" hide-details="auto" v-model="datos_param.neumatico" small
                            readonly></v-text-field> 
                        </v-col> -->
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                <v-btn color="info" @click="guardarIncidencia()" :loading="loading">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { notif } from '@/plugins/notyf'; 
export default {
    props: ['dialog','datos'],
    data() {
        return {
            valid: false,
            loading: false,
            incidente: {},
            descripcionRules: [
                v => !!v || 'Descripción es requerido',
                v => (v && v.length <= 1000) || 'Descripción debe tener menos de 1000 caracteres',
            ],
            item: {
                aplicativo: "vue",
                descripcion: "",
                image_one: null,
                image_two: null
            },
            cabecera: [
                {
                    text: 'Placa',
                    value: 'placa_vehiculo',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Serie',
                    value: 'serie_neumatico',
                    width: 200,
                    class: "grey darken-1 dark white--text"
                },
                {
                    text: 'Opcion',
                    value: 'accion',
                    width: 100,
                    class: "grey darken-1 dark white--text"
                },
            ],
            items: [],
            vehiculo: {},
            vehiculos: [],
            neumaticos: [],
            incidente: {
                placa: null,
                serie: null,
                id_neumatico: null,
                id_vehiculo: null
            },
            nombreusuario: "",
            datos_param:this.datos,
            image_one:'',
            image_two:'',
        };
    },
    computed: {
    },
    methods: {
        ...mapActions("incidente", ["subirImagenes", "guardarIncidente"]),


        onSerieNeumatico() {
            this.neumaticos.forEach(element => {
                if (element.value == this.incidente.id_neumatico) {
                    this.incidente.serie_neumatico = element.text;
                }
            });
        },

        async guardarIncidencia() {
            let status = false;
            if (this.$refs.form.validate()) {
                try {
                    let datosaenviar = { incidente: this.item, detalles: this.items };
                    status = await this.guardarIncidente(datosaenviar);
                    if (status == true) {
                        notif.success("Incidencia enviada con éxito");
                        this.cerrarDialogo();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },

        agregarIncidencia() {
            let existe = false;
            this.items.forEach(element => {
                if (element.id_vehiculo == this.incidente.id_vehiculo && element.id_neumatico == this.incidente.id_neumatico) {
                    existe = true;
                }
            });
            //Si el neumatico no esta en el array incidentes registrar
            if (!existe) {
                if (this.incidente.id_vehiculo != null && this.incidente.id_neumatico != null) {
                    this.items.push({ ...this.incidente });
                } else {
                    if (this.incidente.id_vehiculo == null) {
                        notif.open({ type: 'warning', message: 'La placa del vehículo es requerida' });
                    } else {
                        notif.open({ type: 'warning', message: 'La serie de neumático es requerida' });
                    }
                }
            } else {
                notif.open({ type: 'warning', message: 'El neumático seleccionado ya a sido agregado' });
            }
            this.incidente.serie_neumatico = null;
            this.incidente.id_neumatico = null;
        },

        eliminarItem(item) {
            const pos = this.items.indexOf(item);
            this.items.splice(pos, 1);
        },

        async subirImagen1() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_one = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        async subirImagen2() {
            try {
                let file = document.getElementById('img_firma1').files[0];
                this.item.image_two = await this.subirImagenes(file);
            } catch (error) {
                console.log(error);
            }
        },

        cerrarDialogo() {
            this.$emit("cerrarModalRespuestaIncidencias");
        }
    },
    async mounted() { 
        this.nombreusuario = localStorage.getItem("name");
        console.log(process.env['VUE_APP_API_URL_IMAGE']);
        this.image_one=process.env['VUE_APP_API_URL_IMAGE']+this.datos_param.image_one;
        this.image_two=process.env['VUE_APP_API_URL_IMAGE']+this.datos_param.image_two;
    }
};
</script>