<template>
<v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-form enctype="multipart/form-data" ref="form" v-model="valid_form">
            <v-card>
                <v-card-title>
                    Registro de neumáticos directo a SCRAP
                </v-card-title>
                <v-card-subtitle></v-card-subtitle>
                <!--
                <v-toolbar height="50" color="#6B6A6A" dark>
                    <span class="text-h6">Registro de neumático directo para SCRAP</span>
                    <v-spacer></v-spacer>
                </v-toolbar>
                -->
                <v-card-text>
                    <v-row>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field label="N° serie *" v-model="Item.num_serie" hide-details="auto" :rules="[(v) => !!v || 'Número de serie es requerido']" required></v-text-field>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field label="DOT *" v-model="Item.dot" hide-details="auto" :rules="[(v) => !!v || 'DOT es requerido']" required></v-text-field>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field label="Costo sin IGV *" v-model="Item.precio" hide-details="auto" :rules="[(v) => !!v || 'Costo sin IGV es requerido']" required></v-text-field>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-autocomplete class="mt-5" :items="tipoMoneda" v-model="Item.tipo_moneda" label="Seleccionar tipo moneda *" :rules="[(v) => !!v || 'Tipo de moneda es requerido']" dense></v-autocomplete>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-autocomplete class="mt-1" :items="condicionNeumatico" v-model="Item.nuevo" label="Seleccionar condición neumático *" @change="onCondicion" dense></v-autocomplete>
                        </div>

                        <v-col cols="12" sm="6" md="3" v-if="Item.nuevo == 0">
                            <v-autocomplete class="mt-2" :items="cantidadesReencauche" v-model="Item.cantidad_reencauche" label="Cantidad de reencauches *" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" v-if="Item.nuevo == 0">
                            <v-btn-toggle mandatory>
                                <v-autocomplete :items="Disenios" v-model="Item.id_disenio" label="Diseño de Banda *" :rules="[(v) => !!v || 'Diseño es requerido']" dense></v-autocomplete>
                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalDisennio()">
                                    <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" v-if="Item.nuevo == 0">
                            <v-btn-toggle mandatory>
                                <v-autocomplete :items="Empresas" v-model="Item.id_empresa" label="Empresa Reencauchadora *" :rules="[(v) => !!v || 'Empresa es requerido']" dense></v-autocomplete>
                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalEmpresa()">
                                    <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-btn-toggle mandatory>
                                <v-autocomplete :items="Marcas" v-model="Item.id_marca" label="Seleccionar marca *" @change="cargarModelos(Item.id_marca)" :rules="[(v) => !!v || 'Marca es requerido']" dense></v-autocomplete>

                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalMarca()">
                                    <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-btn-toggle mandatory>
                                <v-autocomplete :items="modeloNeumaticoPorMarcaList" v-model="Item.id_modelo" label="Seleccionar modelo *" :rules="[(v) => !!v || 'Modelo es requerido']" dense></v-autocomplete>

                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalModelo()">
                                    <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-btn-toggle mandatory>
                                <v-autocomplete :items="Medidas" v-model="Item.id_medida" label="Seleccionar medida *" :rules="[(v) => !!v || 'Medida es requerida']" dense></v-autocomplete>

                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small @click="openModalMedida()">
                                    <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>

                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="number" label="Remanente original *" v-model="Item.remanente_original" hide-details="auto" :rules="profundidadRemanenteOriginal" hint="(rec. <= 125 mm)" persistent-hint placeholder="00.00" class="pa-0"></v-text-field>
                        </div>

                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="number" label="Remanente final *" v-model="Item.remanente_final" hide-details="auto" :rules="profundidadRemanenteFinal" hint="(rec. <= 125 mm)" persistent-hint placeholder="00.00" class="pa-0"></v-text-field>
                        </div>

                        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="number" label="Remanente límite *" v-model="Item.remanente_limite" hide-details="auto" :rules="validarRemanenteLimite" hint="(rec. <= 125 mm)" persistent-hint placeholder="00.00" class="pa-0"></v-text-field>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="number" label="Kilómetro inicial " v-model="Item.km_inicial" hide-details="auto" class="pa-0" :rules="[(v) => !!v || 'Km inicial es requerido']"></v-text-field>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="number" label="Kilómetro final " v-model="Item.km_final" hide-details="auto" class="pa-0"></v-text-field>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <v-autocomplete class="mt-5" :items="MotivosScrap" v-model="Item.id_motivo_scrap" label="Motivo SCRAP" hide-details="auto" dense :rules="[(v) => !!v || 'Motivo de scrap es requerido']"></v-autocomplete>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <v-text-field type="date" label="Fecha RETIRO/SCRAP" v-model="Item.fecha_scrap" hide-details="auto" :rules="[(v) => !!v || 'Fecha de scrap o retiro es requerida']"></v-text-field>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <h4 class="font-bold">IMAGEN 1 DEL NEUMATICO EN MAL ESTADO:</h4>
                            <FileAgent @archivosubido="archivosubido1" style="z-index: 0" />
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <h4 class="font-bold">IMAGEN 2 DEL NEUMATICO EN MAL ESTADO:</h4>
                            <FileAgent @archivosubido="archivosubido2" style="z-index: 0" />
                        </div>
                    </v-row>

                    <v-row justify="center">
                        <vue-loaders v-if="loader == true" name="ball-beat" color="red" scale="1"></vue-loaders>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                    <v-btn v-if="Item.id == null" color="info" @click="agregar()">
                        Guardar
                    </v-btn>
                    <v-btn v-else color="info" @click="agregar()"> Actualizar </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <!--MODAL ADD MARCA-->
    <v-dialog v-model="dialogMarca" max-width="300px" @click:outside="cerrarDialogMarca">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-form ref="form_marca" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Nombre de la marca *" required v-model="marca.descripcion" :rules="descripcionRules"></v-text-field>
                            </v-col>
                            <small v-if="mostrarLabelDuplicado">
                                <font color="red">Marca duplicada</font>
                            </small>
                        </v-row>
                    </v-form>
                </v-container>
                <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addMarca(marca)">
                    <font color="white">Agregar</font>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!--MODAL ADD MODELO-->
    <v-dialog v-model="dialogModelo" max-width="300px" @click:outside="cerrarDialogModelo">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-form ref="form_modelo" v-model="valid">
                        <v-row>
                            <v-col cols="12" class="mt-5">
                                <v-autocomplete :items="Marcas" v-model="modelo.id_marca" label="Marca de neumático" :rules="[(v) => !!v || 'Marca es requerido']" dense></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Nombre del modelo *" placeholder="HN08" required v-model="modelo.descripcion" :rules="descripcionRules"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <small>*Indicador de campo requerido</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="addModelo(modelo)">
                    <font color="white">Agregar</font>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!--MODAL ADD MEDIDA-->
    <v-row justify="center" v-if="modal_form_medida">
        <v-dialog v-model="dialogMedida" max-width="450px" @click:outside="cerrarDialogMedida">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form_medida" v-model="valid">
                            <v-row>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="70" persistent-hint label="Ancho de la llanta *" @change="onEscribirDescripcion" v-model="medida.ancho" :rules="[(v) => !!v || 'Ancho es requerido']" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="275" persistent-hint label="Perfil de la llanta *" @change="onEscribirDescripcion" v-model="medida.perfil" :rules="[(v) => !!v || 'Perfil es requerido']" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="4">
                                    <v-text-field hint="22.5" persistent-hint label="Aro de la llanta *" @change="onEscribirDescripcion" v-model="medida.aro" :rules="[(v) => !!v || 'Aro es requerido']"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field hint="70/275R22.5" :readonly="!checkbox" persistent-hint label="Medida/Descripción *" v-model="medida.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" class="pa-0">
                                    <v-checkbox v-model="checkbox" label="Activar descripción"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>*Indicador de campo requerido</small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="addMedida(medida)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD DISENIO-->
    <v-dialog v-model="dialogDisenio" max-width="300px" @click:outside="cerrarDialogDisenio">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-form ref="form_disenio" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Diseño *" required v-model="disenio.nombre" :rules="descripcionRules"></v-text-field>
                            </v-col>
                            <small v-if="mostrarLabelDuplicado">
                                <font color="red">Diseño duplicado</font>
                            </small>
                        </v-row>
                    </v-form>
                </v-container>
                <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addDisenio(disenio)">
                    <font color="white">Agregar</font>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!--MODAL ADD EMPRESA-->
    <v-dialog v-model="dialogEmpresa" max-width="300px" @click:outside="cerrarDialogEmpresa">
        <v-card>
            <v-card-text>
                <v-container>
                    <v-form ref="form_empresa" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="RUC *" required v-model="empresa.ruc" :rules="descripcionRules"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Razon social *" required v-model="empresa.razon_social" :rules="descripcionRules"></v-text-field>
                            </v-col>
                            <small v-if="mostrarLabelDuplicado">
                                <font color="red">RUC duplicado</font>
                            </small>
                        </v-row>
                    </v-form>
                </v-container>
                <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addEmpresa(empresa)">
                    <font color="white">Agregar</font>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import FileAgent from "../FileAgent.vue";
import axios from "axios";
import global from "../../global";
import { notif } from "@/plugins/notyf"

export default {
    props: [
        "dialog",
        "Marcas",
        "Modelos",
        "Medidas",
        "Disenios",
        "MotivosScrap",
        "Empresas",
    ],
    components: {
        FileAgent,
    },
    data() {
        return {
            Item: {},
            tipoMoneda: [{
                    value: 2,
                    text: "$ Dólares",
                },
                {
                    value: 1,
                    text: "S/. Soles",
                },
            ],
            condicionNeumatico: [{
                    value: 1,
                    text: "Nuevo",
                },
                {
                    value: 0,
                    text: "Reencauchado",
                },
            ],
            cantidadesReencauche: [{
                    value: 1,
                    text: 1,
                },
                {
                    value: 2,
                    text: 2,
                },
                {
                    value: 3,
                    text: 3,
                },
                {
                    value: 4,
                    text: 4,
                },
                {
                    value: 5,
                    text: 5,
                },
                {
                    value: 6,
                    text: 6,
                },
            ],
            checkbox: false,
            loader: false,
            valid_form: true,
            modal_scrap: true,
            valid: false,
            dialogMarca: false,
            dialogModelo: false,
            dialogMedida: false,
            dialogDisenio: false,
            dialogEmpresa: false,
            mostrarLabelDuplicado: false,
            marca: {},
            modelo: {},
            medida: {},
            disenio: {},
            empresa: {},
            profundidadRemanenteOriginal: [
                (v) => !!v || "Profundidad es requerida",
                (v) =>
                (v && v.length <= 3) || "Profundidad debe ser menor a 3 caracteres",
            ],
            profundidadRemanenteFinal: [
                (v) => !!v || "Profundidad es requerida",
                (v) =>
                (v && v.length <= 3) || "Profundidad debe ser menor a 3 caracteres",
            ],
            validarRemanenteLimite: [
                (v) => !!v || "Profundidad es requerida",
                (v) =>
                (v && v.length <= 3) || "Profundidad debe ser menor a 3 caracteres",
            ],
            //validarRemanenteOriginal: this.profundidadRemanenteOriginal,
            //validarRemanenteFinal: this.profundidadRemanenteFinal,
            descripcionRules: [(v) => !!v || "Descripción es requerido"],
            modal_form_medida: false,
            modeloNeumaticoPorMarcaList: [],
        };
    },
    methods: {
        ...mapActions("neumatico", ["agregarItemArray"]),

        archivosubido1: function ({
            valor,
            fileRecords
        }) {
            this.Item.ruta1 = fileRecords;
            console.log(this.Item.ruta1);
        },

        archivosubido2: function ({
            valor,
            fileRecords
        }) {
            this.Item.ruta2 = fileRecords;
            console.log(this.Item.ruta2);
        },

        onCondicion: function () {
            this.Item.id_disenio = null;
            this.Item.cantidad_reencauche = null;
            this.Item.id_empresa = null;
            /*
            if (this.Item.nuevo == 1) {
                this.validarRemanenteOriginal = this.profundidadRemanenteOriginal;
                this.validarRemanenteActual = [];
            } else {
                this.validarRemanenteFinal = this.profundidadRemanenteFinal;
                this.validarRemanenteFinal = [];
            }
            */
        },
        onEscribirDescripcion() {
            if (this.medida.ancho == "") {
                this.medida.descripcion = this.medida.perfil +'R'+ this.medida.aro;
            } else if (this.medida.perfil == "" && this.medida.aro == "") {
                this.medida.descripcion = this.medida.ancho;
            }  else if (this.medida.perfil != "" && this.medida.aro == "") {
                this.medida.descripcion = this.medida.ancho + "/" + this.medida.perfil ;
            } else if (this.medida.ancho != "" && this.medida.aro != "" && this.medida.perfil == "") {
                this.medida.descripcion = this.medida.ancho +'R'+ this.medida.aro;
            } else {
                this.medida.descripcion = this.medida.ancho + "/" + this.medida.perfil +'R'+ this.medida.aro;
            }
        },

        inicializarMarca: function () {
            this.marca = {
                descripcion: null,
            };
        },

        inicializarModelo: function () {
            this.modelo = {
                id_medida: null,
                descripcion: null,
            };
        },

        inicializarMedida: function () {
            this.medida = {
                descripcion: null,
                ancho: "",
                perfil: "",
                aro: "",
            };
        },

        inicializarEmpresa: function () {
            this.empresa = {
                razon_social: null,
            };
        },

        inicializarDisenio: function () {
            this.disenio = {
                nombre: null,
            };
        },

        cargarModelos: function (id_marca) {
            this.modeloNeumaticoPorMarcaList = [];
            this.Modelos.forEach((element) => {
                if (element.marca == id_marca) {
                    this.modeloNeumaticoPorMarcaList.push(element);
                }
            });
        },

        openModalMarca: function () {
            this.mostrarLabelDuplicado = false;
            this.$nextTick().then(() => {
                this.modal_form_marca = true;
            });
            this.inicializarMarca();
            if (this.Item.id_marca != null) {
                this.marca.id_marca = this.Item.id_marca;
            }
            this.dialogMarca = true;
        },

        openModalModelo: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarModelo();
            if (this.Item.id_marca != null) {
                this.modelo.id_marca = this.Item.id_marca;
            }
            this.dialogModelo = true;
        },

        openModalMedida: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarMedida();
            this.$nextTick().then(() => {
                this.modal_form_medida = true;
            });
            this.dialogMedida = true;
        },

        openModalDisennio: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarDisenio();
            this.dialogDisenio = true;
        },

        openModalEmpresa: function () {
            this.mostrarLabelDuplicado = false;
            this.inicializarEmpresa();
            this.dialogEmpresa = true;
        },

        addMarca: async function (item) {
            if (
                !global.verificarDuplicados(this.Marcas, item.descripcion.toUpperCase())
            ) {
                //Guardar en la base de datos
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomarca/store",
                    item: item,
                });
                this.Marcas.push({
                    value: dato.id,
                    text: dato.descripcion,
                });
                this.dialogMarca = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addModelo: async function (item) {
            if (
                !global.verificarDuplicados(
                    this.Modelos,
                    item.descripcion.toUpperCase()
                )
            ) {
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomodelo/store",
                    item: item,
                });
                this.Modelos.push({
                    value: dato.id,
                    text: dato.descripcion,
                    marca: dato.marca,
                });
                this.modeloNeumaticoPorMarcaList = [];
                if (this.Item.id_marca != null) {
                    this.cargarModelos(this.Item.id_marca);
                }
                this.dialogModelo = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addMedida: async function (item) {
            if (
                !global.verificarDuplicados(
                    this.Medidas,
                    item.descripcion.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticomedida/store",
                    item: item,
                });
                this.Medidas.push({
                    value: dato.id,
                    text: dato.descripcion,
                });
                this.dialogMedida = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addDisenio: async function (item) {
            if (
                !global.verificarDuplicados(this.Disenios, item.nombre.toUpperCase())
            ) {
                //Guardar en la base de datos
                item.nombre = item.nombre.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "neumaticodisenio/store",
                    item: item,
                });
                this.Disenios.push({
                    value: dato.id,
                    text: dato.nombre,
                });
                this.dialogDisenio = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addEmpresa: async function (item) {
            let existe = false;
            this.Empresas.forEach((element) => {
                if (element.ruc == item.ruc) {
                    existe = true;
                }
            });
            if (!existe) {
                //Guardar en la base de datos
                item.razon_social = item.razon_social.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "empresas/store",
                    item: item,
                });
                this.Empresas.push({
                    value: dato.id,
                    text: dato.razon_social,
                });
                this.dialogEmpresa = false; //Dialog
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        cerrarDialogo() {
            this.$emit("dialog", false);
        },

        cerrarDialogMarca() {
            this.dialogMarca = false;
            this.$refs.form_marca.reset();
        },

        cerrarDialogModelo() {
            this.dialogModelo = false;
            this.$refs.form_modelo.reset();
        },

        cerrarDialogMedida() {
            this.dialogMedida = false;
            this.modal_form_medida = false;
            this.$refs.form_medida.reset();
        },

        cerrarDialogDisenio() {
            this.dialogDisenio = false;
            this.$refs.form_disenio.reset();
        },

        cerrarDialogEmpresa() {
            this.dialogEmpresa = false;
            this.$refs.form_empresa.reset();
        },

        async agregar() {
            try {
                if (this.$refs.form.validate()) {
                    this.loader = true;
                    const url = "neumaticos/storescrap";
                    const data = {
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        neumatico: this.Item,
                    };
                    await axios
                        .post(url, data)
                        .then((response) => {
                            this.loader = false;
                            this.$emit("dialog", false);
                            notif.success("Operación realizada con éxito");
                            setTimeout(() => this.$router.go(), 1000);
                        })
                        .catch((error) => {
                            this.loader = false;
                            this.errorMessage = error.response.data.error;
                            notif.error(this.errorMessage);
                            //console.error("error!", error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {},
};
</script>
