import Vue from 'vue'
import Vuex from 'vuex'
import { axios2 as axios } from "@/interceptor/useApi";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Incidentes: []
  },
  getters: {
  },
  mutations: {
    LlenarIncidentes(state, payload) {
      console.log(payload);
      state.Incidentes = payload;
    }
  },
  actions: {
    guardarIncidente: async function ({ commit }, datos) {
      let url = "support/message/email";
      let data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
        incidente: datos.incidente,
        detalles: datos.detalles
      }
      let rpta = null;
      await axios.post(url, data)
        .then(response => {
          rpta = response.data.status;
        })
      return rpta;
    },

    subirImagenes: async function ({ commit }, file) {
      const formData = new FormData();
      formData.append('image_support', file);
      let url = "support/message/image";
      let rpta = null;

      await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          rpta = response.data.data;
        });
      return rpta;
    },

    cargarIncidentes: async function ({ commit }) {
      try {
        var moment = require("moment");
        const url = "incidente/index";
        let rpta = [];

        await axios
          .get(url)
          .then((response) => {
            let notificaciones = [];
            let descripcion = "";
            notificaciones.push({ header: 'Pendientes' });
            response.data.data.forEach((item, index) => {
                descripcion = item.descripcion != null ? item.descripcion : "";
                let fecha = moment(item.fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
                notificaciones.push({
                  avatar: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
                  title: 'Incidente ' + fecha + ' - S' + item.id,
                  emails_respuesta: item.emails_respuesta,
                  datos:item,
                  subtitle: '<span class="text--primary">' + item.usuario.name + '</span> &mdash; ' + descripcion
                });
            })
            rpta = notificaciones;
          })
          .catch((error) => {
            console.error("error!", error);
          });
          return rpta;
      } catch (error) {
        console.log(error);
      }
    }
  }
}


