import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import router from "@/router";
import Swal from 'sweetalert2';
import global from "@/global";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Ruc: "",
    Items: [],
    ItemTotal: 0,
    RazonSocial: "",
    ItemsPlanta: [],
    ItemsTipo: [],
    ItemsMarca: [],
    ItemsModelo: [],
    ItemsAplicacion: [],
    ItemsConfiguracion: [],
    ItemsNeumaticos: [],
    ItemInstalacion: {},
    Loader: true,
  },
  getters: {
    Items(state) {
      return state.Items;
    },
    ItemTotal(state) {
      return state.ItemTotal;
    },
    RazonSocial(state) {
      return state.RazonSocial;
    },
    Ruc(state) {
      return state.Ruc;
    },
    ItemsPlanta(state) {
      return state.ItemsPlanta;
    },
    ItemsAplicacion(state) {
      return state.ItemsAplicacion;
    },
    ItemsTipo(state) {
      return state.ItemsTipo;
    },
    ItemsMarca(state) {
      return state.ItemsMarca;
    },
    ItemsModelo(state) {
      return state.ItemsModelo;
    },
    ItemsConfiguracion(state) {
      return state.ItemsConfiguracion;
    },
    ItemsNeumaticos(state) {
      return state.ItemsNeumaticos;
    },
  },
  mutations: {
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarItemTotal(state, data) {
      state.ItemTotal = data;
    },
    LlenarRazonSocial(state, data) {
      state.RazonSocial = data;
    },
    LlenarRuc(state, data) {
      state.Ruc = data;
    },
    LlenarItemsPlanta(state, data) {
      state.ItemsPlanta = data;
    },
    LlenarItemsTipo(state, data) {
      state.ItemsTipo = data;
    },
    LlenarItemsAplicacion(state, data) {
      state.ItemsAplicacion = data;
    },
    LlenarItemsMarca(state, data) {
      state.ItemsMarca = data;
    },
    LlenarItemsModelo(state, data) {
      state.ItemsModelo = data;
    },
    LlenarItemsConfiguracion(state, data) {
      state.ItemsConfiguracion = data;
    },
    LlenarItemsNeumaticos(state, data) {
      state.ItemsNeumaticos = data;
    },
    LlenarItemInstalacion(state, data) {
      state.ItemInstalacion = data;
    },
    LlenarLoader(state, data) {
      state.Loader = data;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarVehiculosMinified: async function ({ commit }) {
      const url = "vehiculos/showminified";
      const data = { id_cliente: localStorage.getItem("idcliente") };
      let rpta = [];
      await axios.post(url, data).then((response) => {
        let vehiculos = [];
        response.data.data.forEach((element) => {
          vehiculos.push({
            value: element.id,
            text: element.placa + "/ " + element.codigo,
            configuracion: element.id_configuracion,
            placa: element.placa,
          });
        });
        rpta = vehiculos;
      });
      return rpta;
    },

    cargarVehiculos: async function ({ commit, dispatch }) {
      try {
        const url = "vehiculos/index";
        const data = { id_cliente: localStorage.getItem("idcliente") };
        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado);
            commit("LlenarItemTotal", response.data.success.total);
            commit("LlenarLoader", false);           
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarNeumaticosPorId: async function ({ commit, dispatch }, id) {
      const url = "vehiculos/obtenerneumaticosporid";
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_vehiculo: id,
      };
      let rpta = [];
      await axios
        .post(url, data)
        .then((response) => {
          let array = response.data.data[0]["neumaticos_historial"];
          let neumaticos = [];
          array.forEach((element) => {
            neumaticos.push({
              value: element.id_neumaticos,
              text: element.num_serie,
            });
          });
          rpta = neumaticos;
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
      return rpta;
    },

    cargarDatosAdicionales: async function ({ commit, dispatch }) {
      try {
        const url = "vehiculos/list";
        const data = { id_cliente: localStorage.getItem("idcliente") };
        await axios
          .post(url, data)
          .then((response) => {
            var arrayPlanta = [];
            response.data.success.resultado.plantaselect.forEach(
              (element, i) => {
                arrayPlanta[i] = { value: element.id, text: element.nombre };
              }
            );
            var arrayTipo = [];
            response.data.success.resultado.tipov.forEach((element, i) => {
              arrayTipo[i] = { value: element.id, text: element.nomtipo, img: global.ruta_image +
                "images/tipovehiculo/" +element.imagen };
            });
            var arrayAplicacion = [];
            response.data.success.resultado.aplicacionv.forEach(
              (element, i) => {
                arrayAplicacion[i] = {
                  value: element.id,
                  text: element.aplicacion,
                };
              }
            );
            var arrayMarca = [];
            response.data.success.resultado.marcav.forEach((element, i) => {
              arrayMarca[i] = { value: element.id, text: element.marca };
            });
            var arrayModelo = [];
            response.data.success.resultado.modelov.forEach((element, i) => {
              arrayModelo[i] = {
                value: element.id,
                text: element.modelo,
                marca: element.id_marca,
              };
            });
            /* var arrayConfiguracion = []
            response.data.success.resultado.configurav.forEach((element,i) => {
              arrayConfiguracion[i] = { value: element.id, text: element.nombre+' / '+ element.nroneumaticos +' / '+ element.tipo_configuracion } 
            }); */
            commit("LlenarItemsPlanta", arrayPlanta);
            commit("LlenarItemsTipo", arrayTipo);
            commit("LlenarItemsAplicacion", arrayAplicacion);
            commit("LlenarItemsMarca", arrayMarca);
            commit("LlenarItemsModelo", arrayModelo);
            commit(
              "LlenarRazonSocial",
              response.data.success.resultado.clienteselect.razon_social
            );
            commit(
              "LlenarRuc",
              response.data.success.resultado.clienteselect.ruc
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarConfiguracion: async function ({ commit, dispatch }, tipo) {
      try {
        const url = "getconfiguracion";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id: tipo,
        };
        if (tipo == null) {
          commit("LlenarItemsConfiguracion", []);
        } else {
          await axios
            .post(url, data)
            .then((response) => {
              var arrayConfiguracion = [];
              response.data.success.resultado.forEach((element, i) => {
                arrayConfiguracion[i] = {
                  value: element.id,
                  text:
                    element.nombre +
                    " / " +
                    element.nroneumaticos +
                    " / " +
                    element.tipo_configuracion,
                };
              });
              commit("LlenarItemsConfiguracion", arrayConfiguracion);
            })
            .catch((error) => {
              this.errorMessage = error.response.data;
              console.error("error!", error);
              dispatch("messageErrorAlert");
            });
        }
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarPosicionNeumatico: async function ({ commit, dispatch }, tipo) {
      try {
        const url = "getposicionneumaticos";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id: tipo,
        };

        await axios
          .post(url, data)
          .then((response) => {
            var arrayNeumaticos = [];
            response.data.success.resultado.forEach((element, i) => {
              arrayNeumaticos[element.ubicacion] = element; 
            });
            commit("LlenarItemsNeumaticos", arrayNeumaticos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarDatosInstalacion: async function ({ commit, dispatch }, id) {
      try {
        const url = "showVehiculos/" + id;
        const data = { id_cliente: localStorage.getItem("idcliente") };
        await axios
          .post(url, data)
          .then((response) => {
            var id = response.data.success.resultado.id;
            var placa = response.data.success.resultado.placa;
            var codigo = response.data.success.resultado.codigo;
            var configuracion = response.data.success.resultado.configuracion;
            var numneumaticos = response.data.success.resultado.nroneumaticos;
            var tipo_costo = response.data.success.resultado.tipo_costo;
            var ruta = response.data.success.resultado.ruta;
            var fecha_ultima_inspeccion = response.data.success.resultado.fecha_ultima_inspeccion;
            var km_ultima_inspeccion = response.data.success.resultado.km_ultima_inspeccion;
            var id_configuracion =
              response.data.success.resultado.id_configuracion;
            var pos_inicial = response.data.success.resultado.pos_inicial;

            var vehiculo = {
              id: id,
              placa: placa,
              codigo: codigo,
              configuracion: configuracion,
              numneumaticos: numneumaticos,
              fechainstalacion: null,
              kilometraje: null,
              fecha_validate:fecha_ultima_inspeccion,
              km_validate:km_ultima_inspeccion,
              id_aplicacion: ruta,
              pos_inicial: pos_inicial,
              tipo_costo: tipo_costo, //adicionales
              id_configuracion: id_configuracion, //adicionales
            };

            commit("LlenarItemInstalacion", vehiculo);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    agregarVehiculo: async function ({ commit, dispatch }, datos) {
      var url =
        datos.id == "" || datos.id == null
          ? "vehiculos/store"
          : "vehiculos/update";
      //var url = "vehiculos/store"
      let request = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
        vehiculo: datos,
      };

      await axios
        .post(url, request)
        .then((response) => {
          commit("LlenarLoader", true);
          dispatch("cargarVehiculos");
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },

    actualizarItemNeumatico: function (context, data) {
      context.commit("actualizarItemNeumatico", data);
    },

    guardarDatosInstalacion: async function ({ commit, dispatch }, datos) {
      var url = "vehiculos/storeinstalacion";
      (datos.id_cliente = localStorage.getItem("idcliente")),
        (datos.id_usuario = localStorage.getItem("id")),
        await axios
          .post(url, datos)
          .then((response) => {})
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
    },

    existeConfiguracionEnVehiculos: async function ({ commit, dispatch }, id) {
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_configuracion: id,
      };

      let rpta = null;
      try {
        const url = "vehiculos/existeconfiguracion";
        await axios
          .post(url, data)
          .then((response) => {
            rpta = response.data.success.resultado;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
      }
      return rpta;
    },

    agregarItemArray: async function ({ commit, dispatch }, datos) {
      var url = datos.url;
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
        item: datos.item,
      };
      let rpta = null;

      await axios
        .post(url, data)
        .then((response) => {
          rpta = response.data.success;
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
      return rpta;
    },
 
  },
};
